import React,{useState,useEffect,useRef}  from "react";
import Layout from "../components/layout";
import FormGenerator from "../components/form/FormGenerator";
import List from "../components/form/List";
import styled from "styled-components";
import Data from "../config/DJs"
import Edit from "../components/form/Edit";
import {BaseSelect,BaseFormField,FormSubmit} from "../styles/Global"
import { GetList } from "../utils/ApiFunctions";
import axios from "axios";
import mainjson from '../config/main';
import writeXlsxFile from 'write-excel-file'



const Header = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 14px 10px;
box-shadow: 1px 0 20px rgba(0,0,0,.08);
`

const Title = styled.div`
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-left:10px;
`
const Right = styled.div`
position:absolute;
right:25px;
`

const ListContainer = styled.div`
  padding-bottom:20px;
`;

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 400;
`;


const InputContainer = styled.div`
 
`;

const Select = styled(BaseSelect)`

`

const Top = styled.div`
padding:25px;

`

const Container = styled.div`
padding:25px;
background-color:white;
`

function download(filename, text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
}

export default function Page() {
    const [options, setOptions] = useState([]);
    const [text, setCurrentText] = useState(null);
    const input = useRef();
    const inputRef = useRef();
    const [value, setValue] = useState("all");
    const [exported, setExported] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [importasvalue, setimportasValue] = useState(0);
    const filenameRef = useRef();
    const [active, setActive] = useState(true);

    function changed(e){
        setValue(e.target.value);
        checkActive();
    }

    function importaschanged(e){
        setimportasValue(e.target.value);
        console.log(e.target.value);
    }

    async function getFeed(){
        let data = await GetList("sections",0,9999999,"section_name asc","",{},-1);
        let optionarray = [];
        console.log("data = ",data);
        for (let i =0;i<data.records.data.length;i++) {
            let section = data.records.data[i];
            optionarray.push({key:section.section_id,value:section.section_name});
        }
        setOptions(optionarray);
    }

    function checkActive(){
        console.log("checkactive ",value,exported);
    }

    function makeData(data) {
        let d = data;
        if (typeof data == 'string') {
            d = JSON.parse(data);
        }
        let returndata = [];

        for (let i = 0;i<d.length;i++) {
            let r = d[i];
            let newrow = [];
            for (let i2 = 0;i2<r.length;i2++) {
               // console.log("dit is een row ",i,i2,r[i2]);

                let col = r[i2];

                if (i == 0) {
                    newrow.push({
                        value: col,
                        fontWeight: 'bold'
                    });
                    
                }
                else {

                    newrow.push({
                        value: col
                    });
                }
            }

            returndata.push(newrow);


            //console.log('row = ',r);

        }



        return returndata;
    }

    async function makeExcel(data){

        let outputdata = makeData(data);

       // console.log(outputdata);

        await writeXlsxFile(outputdata, {
            fileName: 'file.xlsx'  
        })
    }

    async function submit(){
        setExported(false)
        let resultdata = "";
        setExporting(true);
        let body = {
            "type":"export",
            "CMSUser-Token":localStorage.getItem('token'),
            "CMSUser-Agent":navigator.userAgent,
            "section":value,
            "state":importasvalue
        }
    
        await axios({
            method: "post",
            url: mainjson().export_path,
            data: body,
          })
        .then(async (response) => {
           if (response.data) {
               resultdata = response.data;
               console.log("resultdata",resultdata)

               //await makeExcel(resultdata.csvdata);
              
           }
        })
        .catch((error) => {
            console.log("error "+error);
            setExported(true);
            setExporting(false);
        });

        if (resultdata.url) {
            await axios({
                method: "get",
                url: resultdata.url,
              })
            .then(async (response) => {
               if (response.data) {
                   await makeExcel(response.data);
                   setExported(true);
                   setExporting(false);
               }
            })
            .catch((error) => {
                console.log("error "+error);
                setExported(true);
                setExporting(false);
            });
    
        }
    
        return resultdata;
    }

    useEffect(() => {
        getFeed();
    }, []);    

  return (
    <Layout>
        <Header>
            <Title>
                    Export Excel
            </Title>

        </Header>
        <Top>
        <Container>
        <ListContainer>
                <ListTitle>Select Section</ListTitle>
                <Select onChange={changed} value={value}>
                    <option value="all">All</option>
                {options.map((val, index) => {
                    return <option value={val.key} key={val.key} ref={input}>{val.value}</option>
                })}
                </Select>
            </ListContainer>
            <ListContainer>
                <ListTitle>Export types</ListTitle>
                <Select onChange={importaschanged} value={importasvalue}>
                <option value='0'>All</option>
                <option value='1'>All Active</option>
                <option value='2'>Inactive</option>
                <option value='3'>Active kid</option>
                <option value='4'>Active adult</option>
                </Select>
            </ListContainer>
            <ListContainer>
                {exported && <p>Exported</p>

                }
                
                {!exporting &&
                <FormSubmit onClick={submit}>Export</FormSubmit>
                }
                
                {exporting && " Exporting, please wait"

                }
                </ListContainer>
            
        
            </Container>
            </Top>
    </Layout>
  );
}